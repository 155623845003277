<template>
	<div
		:class="{ 'card': !noBackground }"
		class="slide border-0">
		<!-- Image -->
		<div
			:class="{ 'card-image': !noBackground }"
			class="mx-auto">
			<b-button
				v-if="enableZoom"
				v-b-modal="`${modalPrefix}-${activeImage}`"
				type="button"
				class="pointer modal-container p-0"
				variant="link">
				<img
					:src="currentImage"
					class="img-fluid mx-auto">
			</b-button>
			<img
				v-else
				:src="currentImage"
				class="img-fluid mx-auto">
			<span
				v-if="hasMultipleImages"
				:class="{'d-none' : ['xs','sm'].includes(windowWidth)}"
				class="actions prevImage h2 chevron pointer mr-auto ml-0"
				@click="prevImage">
				<i class="fas fa-chevron-left" />
			</span>
			<span
				v-if="hasMultipleImages"
				:class="{'d-none' : ['xs','sm'].includes(windowWidth)}"
				class="actions nextImage h2 chevron pointer ml-auto mr-0"
				@click="nextImage">
				<i class="fas fa-chevron-right" />
			</span>
			<img
				v-if="badge"
				:src="badge"
				style="position: absolute; top: 10px; right: 10px; height: 25px;"
				class="img-fluid mx-auto pointer"
				alt="Badge">
		</div>
		<!-- Thumbnails carousel -->
		<div
			v-if="hasMultipleImages"
			:class="{ 'card-body': !noBackground }"
			class="scrolling-wrapper py-2 px-0 text-center">
			<div
				v-for="(image, index) in productImages"
				:key="image.id"
				class="d-inline-block pointer thumb-container mx-1"
				:class="(activeImage === index) ? 'active' : ''"
				:style="(activeImage === index && highlightColor) ? `border: 1px solid ${highlightColor};` : ''"
				@click="activateImage(index)">
				<img
					style="max-height: 100px; max-width: 100px;"
					:src="image.thumbnail">
				<div class="middle" />
			</div>
		</div>
		<!-- Modal -->
		<b-modal
			:id="`${modalPrefix}-${activeImage}`"
			centered
			modal-class="media-modal"
			hide-footer
			size="md">
			<div class="text-center">
				<img
					:src="currentImage"
					class="img-fluid mx-auto">
			</div>
		</b-modal>
	</div>
</template>

<script>
import WindowSizes from '@/mixins/WindowSizes';

export default {
	name: 'ProductImageCarousel',
	mixins: [WindowSizes],
	props: {
		images: {
			type: Object,
			default() {
				return {};
			},
		},
		groupToShow: {
			type: String,
			default: '',
		},
		enableZoom: {
			type: Boolean,
			default: false,
		},
		noBackground: {
			type: Boolean,
			default: false,
		},
		modalId: {
			type: String,
			default: '',
		},
		highlightColor: {
			type: String,
			default: '',
		},
		badge: {
			type: String,
			default: null,
		},
	},
	data() {
		return {
			activeImage: 0,
		};
	},
	computed: {
		productImages() {
			return this.images[this.groupToShow] ?? [];
		},
		currentImage() {
			try {
				return this.productImages[this.activeImage].image;
			} catch (error) {
				return [];
			}
		},
		hasMultipleImages() {
			try {
				return this.productImages.length > 1;
			} catch (error) {
				return false;
			}
		},
		modalPrefix() {
			return this.modalId ? this.modalId : 'modal';
		},
	},
	watch: {
		groupToShow() {
			this.activeImage = 0;
		},
	},
	methods: {
		nextImage() {
			let active = this.activeImage + 1;
			if (active >= this.productImages.length) active = 0;
			this.activateImage(active);
		},
		prevImage() {
			let active = this.activeImage - 1;
			if (active < 0) active = this.productImages.length - 1;
			this.activateImage(active);
		},
		activateImage(imageIndex) {
			this.activeImage = imageIndex;
		},
	},
};
</script>
<style scoped>
.actions {
	position: absolute;
	top: 160px;
	bottom: 0;
	height: max-content;
}
.prevImage {
	left: 0;
	right:100;
}
.nextImage {
	right: 0;
	left:100;
}
.thumb-container:hover img {
	opacity: 0.3;
	-webkit-transition: 0.2s ease;
	transition: 0.5s ease;
}
.thumb-container:hover .middle {
	opacity: 1;
}
.slide:hover .chevron{
	visibility: visible;
	opacity: 1;
	-webkit-transition: 0.3s ease;
	transition: visibility 0.3s, opacity 0.3s linear, color 0.3s ease;
}
.slide .chevron{
	visibility: hidden;
	opacity: 0;
	-webkit-transition: 0.3s ease;
	transition: visibility 0.3s, opacity 0.3s linear, color 0.3s ease;
}
.middle {
	opacity: 0;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	text-align: center;
}
.media-modal img {
  transform: scale(1.4);
}
.modal-container img {
	cursor: zoom-in;
}
.chevron:hover {
	color: var(--product-primary-color);
}
.thumb-container {
	border: 1px solid #c8ced3;
	width: 102px;
	height: 102px;
}
</style>
<style>
.media-modal {
	background: rgba(0, 0, 0, 0.8);
}
.modal-header {
	z-index: 9;
}
</style>
